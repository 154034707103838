<template>
  <div>
    <div class="grid-inputs">
      <v-text-field
        v-for="(i, index) in code"
        :key="index"
        :ref="`input${index}`"
        v-model="code[index]"
        v-mask="['#']"
        autocomplete="off"
        class="pin-input"
        :class="{ 'pin-input-error': error }"
        :disabled="loading"
        :error-messages="error ? ' ' : ''"
        hide-details
        outlined
        type="text"
        @focus="focusedIndex = index"
        @input="handleFocus(index + 1)"
        @keydown.delete="onDelete(index)"
        @paste="handlePaste($event)"
      />
    </div>
    <span v-if="error" class="pin-subtitle-error">{{ error }}</span>
  </div>
</template>

<script>
export default {
  name: 'PinInput',
  props: {
    error: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      code: new Array(6),
      focusedIndex: 0,
      timeout: null,
    }
  },
  computed: {
    strCode() {
      const code = this.code.filter((c) => c).join('')
      return code
    },
  },
  watch: {
    strCode(newVal) {
      if (newVal.length === 6) this.debounceAndSendCode()
    },
    loading() {
      if (this.error && !this.loading) {
        const time = setTimeout(() => {
          this.$refs.input5[0].$el.getElementsByTagName('input')[0].focus()
          clearTimeout(time)
        }, 300)
      }
    },
  },
  mounted() {
    const time = setTimeout(() => {
      this.$refs.input0[0].$el.getElementsByTagName('input')[0].focus()
      clearTimeout(time)
    }, 300)
  },
  methods: {
    handleFocus(index) {
      this.$emit('remove-error')
      const refIndex = `input${index}`
      if (this.code[index - 1] && index < 6) this.$refs[refIndex][0].$el.getElementsByTagName('input')[0].focus()
    },
    onDelete(index) {
      if (this.code[this.focusedIndex]) return
      const refIndex = `input${index - 1}`
      if (this.code[index - 1]) this.$refs[refIndex][0].$el.getElementsByTagName('input')[0].focus()
    },
    handlePaste(event) {
      let pin = event.clipboardData.getData('text')
      if (pin.length > 6) pin = pin.substring(0, 6)
      this.code = []
      this.$refs['input0'][0].$el.getElementsByTagName('input')[0].focus()
      for (let i = 0; i < pin.length; i++) {
        this.code[i] = pin[i]
      }
      if (pin.length < 6) this.$refs[`input${pin.length - 1}`][0].$el.getElementsByTagName('input')[0].focus()
    },
    debounceAndSendCode() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$emit('send-code', this.strCode)
      }, 100)
    },
  },
}
</script>

<style lang="scss" scoped>
$tablet-size: 960px;
.grid-inputs {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
  max-width: 410px;
}
.pin-input {
  & ::v-deep input {
    text-align: center;
    font-size: 25px;
  }
}

.pin-subtitle-error {
  width: 100%;
  text-align: center;
  font-weight: 400;
  color: #e03210;
  font-size: 14px;
  display: block;
}

@media screen and (max-width: $tablet-size) {
  .grid-inputs {
    max-width: 100%;
  }
}
</style>
